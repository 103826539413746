/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getApiUrl } from "../../../utils/AuthUtils";
import axios from "axios";

const Logout = () => {
  const loginUrl = localStorage.getItem("loginUrl") || "/login";
  const navigate = useNavigate();

  useEffect(() => {
    onLogout();
  }, []);

  const onLogout = () => {
    axios
      .get(getApiUrl() + "/api/logout")
      .then(function (response) {
        if (response.status === 200) {
          localStorage.removeItem("token");
          localStorage.setItem("isLogin", "0");
          navigate(loginUrl);
        }
      })
      .catch(function (error) {
        console.log(error);
        localStorage.removeItem("token");
        localStorage.setItem("isLogin", "0");
        navigate("/login");
      });
  };
};

export default Logout;
