import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncStatuses } from "../../../../utils/Constants";
import { DEALS_API } from "./constants";

const initialState = {
    dealsData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    dealDetailsByID: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    kanbanData: null,
    filters: {
        search: "",
        conditions: "",
        orderField: "name",
        order: "asc",
        limit: 10,
        offset: 0,
    },
    createDealStatus: asyncStatuses.IDLE,
    updateDealStatus: asyncStatuses.IDLE,
    selectedPipelineId: null,
};

// Transform deals into Kanban format
const transformToKanbanFormat = (deals, pipelines, selectedPipelineId) => {
    if (!pipelines || !selectedPipelineId || !deals) return null;

    const kanbanData = {};
    const selectedPipeline = pipelines.find((p) => p.id === selectedPipelineId);
    if (!selectedPipeline) return null;
    const sortedStages = [...selectedPipeline.stages].sort((a, b) => a.stage_order - b.stage_order);

    sortedStages.forEach((stage) => {
        kanbanData[stage.stage_id] = {
            stage_name: stage.stage_name,
            stage_order: stage.stage_order,
            is_final: stage.is_final_stage || false,
            color: stage.color || "gray",
            deals: [],
        };
    });



    deals.forEach((deal) => {
        const stageId = deal.pipeline_info?.current_stage_id;

        kanbanData[stageId].deals.push({
            id: deal.deal_id.toString(),
            abbreviation: deal.name || "?",
            amount: deal.value || "N/A",
            created_at: deal.created_at ? new Date(deal.created_at).toLocaleDateString() : "N/A",
            updated_at: deal.updated_at ? new Date(deal.updated_at).toLocaleDateString() : "N/A",
            tags: Array.isArray(deal.tags) && deal.tags.length > 0 ? deal.tags : ["No Tags"],
            pipeline_id: selectedPipelineId,
            stage_id: stageId,
        });
    });

    return kanbanData;
};

// Fetch all deals
export const fetchDeals = createAsyncThunk("deals/fetchDeals", async (_, { getState }) => {
    const state = getState();
    const { search, orderField, order, limit, offset, conditions } = state.deals.filters;
    const pipelines = state.masters.filteredPipelines;
    const selectedPipelineId = state.deals.selectedPipelineId;
    const params = new URLSearchParams({
        filter: search,
        conditions,
        orderField,
        order,
        limit,
        offset,
    }).toString();

    const response = await axios.get(`${DEALS_API.ALL}?${params}`);
    const { success, data, total } = response.data;

    if (success) {
        const transformedData = data.map((deal) => ({
            ...deal,
            tags: JSON.parse(deal.tags) || [],
            pipeline_info: JSON.parse(deal.pipeline_info) || {},
            all_stages: JSON.parse(deal.all_stages) || [],
            pipeline_logs: JSON.parse(deal.pipeline_logs) || [],
            contacts: JSON.parse(deal.contacts) || [],
            modules_submodules: JSON.parse(deal.modules_submodules) || [],
        }));
        let kanbanFormattedData = {};
        if (selectedPipelineId && pipelines) {
            kanbanFormattedData = transformToKanbanFormat(transformedData, pipelines, selectedPipelineId);
        }
        return { total, deals: transformedData, kanbanData: kanbanFormattedData };
    } else {
        throw new Error("Failed to fetch deals");
    }
});

// Create a new deal
export const createDeal = createAsyncThunk("deals/createDeal", async (dealData) => {
    const response = await axios.post(DEALS_API.CREATE, dealData);
    const { success, data } = response.data;
    if (success) return data;
    else throw new Error("Failed to create deal");
});

// Fetch a single deal by ID
export const fetchDealById = createAsyncThunk("deals/fetchDealById", async (id) => {
    const response = await axios.get(`${DEALS_API.ALL}/${id}`);
    const { success, data } = response.data;
    if (success) {
        return {
            ...data,
            tags: JSON.parse(data.tags) || [],
            pipeline_info: JSON.parse(data.pipeline_info) || {},
            all_stages: JSON.parse(data.all_stages) || [],
            pipeline_logs: JSON.parse(data.pipeline_logs) || [],
            contacts: JSON.parse(data.contacts) || [],
            modules_submodules: JSON.parse(data.modules_submodules) || [],
        };
    } else throw new Error("Failed to fetch deal details");
});

// Update an existing deal
export const updateDeal = createAsyncThunk("deals/updateDeal", async ({ id, dealData }) => {
    const response = await axios.put(`${DEALS_API.ALL}/${id}`, dealData);
    const { success, data } = response.data;
    if (success) return data;
    else throw new Error("Failed to update deal");
});

// Update deal stage
export const updateDealStage = createAsyncThunk(
    "deals/updateDealStage",
    async ({ dealId, newStageId, pipelineId, logMessage, userId, filter }, { dispatch }) => {
        try {
            const response = await axios.post(DEALS_API.UPDATE_STAGE, {
                dealId,
                newStageId,
                pipelineId,
                logMessage,
            });
            if (response.data.success) {
                dispatch(fetchDeals());
                return { dealId, newStageId, pipelineId };
            } else throw new Error("Failed to update deal stage");
        } catch (error) {
            throw error;
        }
    }
);

const dealsSlice = createSlice({
    name: "deals",
    initialState,
    reducers: {
        updateFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        setSelectedPipelineId(state, action) {
            state.selectedPipelineId = action.payload;
            state.dealsData.kanbanData = null;
        },
        resetDealDetailsByID(state) {
            state.dealDetailsByID = {
                data: null,
                status: asyncStatuses.IDLE,
            };
        },
        resetFilters(state){
            state.filters = {
                search: "",
                conditions: "",
                orderField: "name",
                order: "asc",
                limit: 10,
                offset: 0,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeals.pending, (state) => {
                state.dealsData.status = asyncStatuses.LOADING;
            })
            .addCase(fetchDeals.fulfilled, (state, action) => {
                state.dealsData.status = asyncStatuses.SUCCEEDED;
                state.dealsData.data = action.payload;
                state.kanbanData = action.payload.kanbanData;
            })
            .addCase(fetchDeals.rejected, (state) => {
                state.dealsData.status = asyncStatuses.FAILED;
            });
        builder
            .addCase(createDeal.pending, (state) => {
                state.createDealStatus = asyncStatuses.LOADING;
            })
            .addCase(createDeal.fulfilled, (state) => {
                state.createDealStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(createDeal.rejected, (state) => {
                state.createDealStatus = asyncStatuses.FAILED;
            });
        builder
            .addCase(updateDeal.pending, (state) => {
                state.updateDealStatus = asyncStatuses.LOADING;
            })
            .addCase(updateDeal.fulfilled, (state) => {
                state.updateDealStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(updateDeal.rejected, (state) => {
                state.updateDealStatus = asyncStatuses.FAILED;
            });
        builder
            .addCase(fetchDealById.pending, (state) => {
                state.dealDetailsByID.status = asyncStatuses.LOADING;
            })
            .addCase(fetchDealById.fulfilled, (state, action) => {
                state.dealDetailsByID.status = asyncStatuses.SUCCEEDED;
                state.dealDetailsByID.data = action.payload;
            })
            .addCase(fetchDealById.rejected, (state) => {
                state.dealDetailsByID.status = asyncStatuses.FAILED;
            });
    },
});

export const { updateFilters, resetFilters, setSelectedPipelineId, resetDealDetailsByID } = dealsSlice.actions;
export default dealsSlice.reducer;
