import axios from "axios";
import { getApiUrl } from "./AuthUtils";

export const getEmployeeStatusBadgeClass = (status) => {
    let badgeClass = "btn btn-white btn-sm pe-none";
  
    switch (status) {
      case "active":
      case "probation":
        badgeClass += " badge-outline-success status-color";
        break;
      case "retired":
      case "suspended":
      case "temporary layoff":
        badgeClass += " badge-outline-warning status-color";
        break;
      case "contract ended":
      case "deceased":
      case "deleted":
      case "permanent layoff":
      case "resigned":
      case "terminated":
        badgeClass += " badge-outline-danger status-color";
        break;
      default:
        badgeClass += "badge-outline-primary status-color"; // Default class for unlisted statuses
    }
      
    return badgeClass;
};
export const getEmployeeListData = async () =>{
    try {
      const response = await axios.get(`${getApiUrl()}/api/employees/employee-list`);
      // console.log('axios data', response.data.data);
      return response.data.data; // Assuming the data you need is in the response.data
    } catch (error) {
        console.error("Error fetching employee list data:", error);
        throw error; // Optionally re-throw the error to handle it elsewhere
    }
};
export const getEmployeeMastersListData = async () =>{
    try {
      const response = await axios.get(`${getApiUrl()}/api/employees/masters-list`);
      // console.log('axios data', response.data.data);
      return response.data.data; // Assuming the data you need is in the response.data
    } catch (error) {
        console.error("Error fetching employee list data:", error);
        throw error; // Optionally re-throw the error to handle it elsewhere
    }
};
export const getSkillData = async () =>{
    try {
      const response = await axios.get(`${getApiUrl()}/api/masters/skills/skill-list`);
      // console.log('axios data', response.data.data);
      return response.data.data; // Assuming the data you need is in the response.data
    } catch (error) {
        console.error("Error fetching employee list data:", error);
        throw error; // Optionally re-throw the error to handle it elsewhere
    }
};

export const setLocalStorageJSONData = (key, data) => {
  let parsedString = JSON.stringify([]);
  if(typeof data !== 'undefined'){
    parsedString = JSON.stringify(data)
  }
  localStorage.setItem(
    key,
    parsedString
  )
}

export const getLocalStorageJSONData = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const getStatusBadgeClass = (status) => {
  let badgeClass = 'btn btn-white btn-sm pe-none ';
  switch (status) {
      case "active":
        badgeClass += "badge-outline-success status-color";
        break;
      case "inactive":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      case "deleted":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      default:
        badgeClass += "badge-outline-success status-color-dg"; // Default class for unlisted statuses
  }
  return badgeClass;
}

export const getCandidateStatusBadgeClass = (status) => {
  let badgeClass = 'btn btn-white btn-sm pe-none ';
  switch (status) {
      case "active":
        badgeClass += "badge-outline-success status-color";
      case "preboarding":
        badgeClass += "badge-outline-warning status-color";
      case "onboarding":
        badgeClass += "badge-outline-warning status-color";
        break;
      case "inactive":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      case "deleted":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      default:
        badgeClass += "badge-outline-success"; // Default class for unlisted statuses
  }
  return badgeClass;
}

export const hasPermissionBySubmoduleSlugs = (modules, submoduleSlugs, permission = "read") => {
  // Iterate over each submodule slug in the array
  for (const slug of submoduleSlugs) {
    // Check if any module contains a submodule with the matching slug and read permission
    for (const module of modules) {
      const submodule = module.submodules.find((sub) => sub.slug === slug);

      // If submodule is found and has read permission, return true
      if (submodule && submodule.userPermissions[permission] === true) {
        return true;
      }
    }
  }

  // If no matching submodule with read permission is found, return false
  return false;
}
export const getWeeksOfMonth = () => {

  const weeks = [];
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let startDate = new Date(year, month, 1); // First day of the month
  let endDate = new Date(year, month + 1, 0); // Last day of the month

  let weekCounter = 1;

  // Get the first day of the week (Sunday) for the current month's first week
  let firstDayOfWeek = startDate.getDay(); // 0 is Sunday, 6 is Saturday

  // Function to format date as "DD MMM YYYY"
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short"};
    return date.toLocaleDateString("en-GB", options);
  };

  // Loop through the days in the month and calculate week start dates
  while (startDate <= endDate) {
    weeks.push(formatDate(startDate)); // Push the formatted start date
    // Move to the next week
    startDate.setDate(startDate.getDate() + (7 - firstDayOfWeek)); 
    firstDayOfWeek = 0; // After first iteration, reset to full 7-day week
  }


  return weeks;

}

export const getDashboardData = async () =>{
  try {
    const response = await axios.get(`${getApiUrl()}/api/dashboard`);
    // console.log('axios data', response.data.data);
    return response.data.data; // Assuming the data you need is in the response.data
  } catch (error) {
      console.error("Error fetching employee list data:", error);
      throw error; // Optionally re-throw the error to handle it elsewhere
  }
};

export const getInitials = (text) => {
    if (!text) return ""; // Handle empty or undefined entity names
    return text
        .split(" ") // Split the string into words
        .map(word => word[0].toUpperCase()) // Get the first letter of each word and convert to uppercase
        .join(""); // Join the letters together
};

/**
 * Creates or updates a URL parameter string.
 * @param {string} key - The key of the parameter to update or add.
 * @param {string} value - The value to assign to the key.
 * @param {string} existingString - The existing query string (e.g., "search=xyz&pipeline_id=9").
 * @returns {string} - Updated query string with the new or updated parameter.
 */
/**
 * Updates or creates query parameters from an array of key-value objects.
 * @param {Array} keyValues - Array of objects with keys and values [{ key: "search", value: "abc" }, { key: "pipeline_id", value: "15" }]
 * @param {string} existingString - Existing query string (e.g., "search=xyz&pipeline_id=9")
 * @returns {string} - Updated query string with new or updated parameters.
 */
export const updateQueryParams = (keyValues, existingString) => {
    const params = new URLSearchParams(existingString);

    keyValues.forEach(({ key, value }) => {
        if (value === null || value === undefined || value === "") {
            params.delete(key); // Remove if value is empty
        } else {
            params.set(key, value); // Update or create parameter
        }
    });

    return params.toString(); // Return updated query string
};