// PIPELINES Base API URL
import {getApiUrl} from "../../../../utils/AuthUtils";

const LEADS_BASE_URL = `${getApiUrl()}/v1/api/crm/leads`;

// Leads API Endpoints
export const LEADS_API = {
    ALL: `${LEADS_BASE_URL}`, // GET - Fetch all leads (with filtering, sorting, pagination)
    CREATE: `${LEADS_BASE_URL}/create`, // POST - Create a new leads
    UPDATE_STAGE: `${LEADS_BASE_URL}/change-stage`, // POST - Update the stage
}
