import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncStatuses } from "../../../../utils/Constants";
import { PIPELINES_API } from "./constants";

const initialState = {
    pipelinesData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    filters: {
        search: "",
        orderField: "pipeline_name",
        order: "asc",
        limit: 10,
        offset: 0,
    },
    createPipelineStatus: asyncStatuses.IDLE,
    updatePipelineStatus: asyncStatuses.IDLE,
};

// Async thunk to fetch pipelines
export const fetchPipelines = createAsyncThunk(
    "pipelines/fetchPipelines",
    async (_, { getState }) => {
        const state = getState().pipelines;
        const { search, orderField, order, limit, offset } = state.filters;

        const params = new URLSearchParams({
            filter: search,
            orderField,
            order,
            limit,
            offset,
        }).toString();

        const response = await axios.get(`${PIPELINES_API.ALL}?${params}`);

        const { success, data, total } = response.data;

        if (success) {
            const transformedData = data.map((pipeline) => ({
                ...pipeline,
                stages: JSON.parse(pipeline.stages),
                module_mappings: JSON.parse(pipeline.module_mappings),
                access_users: JSON.parse(pipeline.access_users),
            }));
            return { total, pipelines: transformedData };
        } else {
            throw new Error("Failed to fetch pipelines");
        }
    }
);

// Async thunk to create a new pipeline
export const createPipeline = createAsyncThunk(
    "pipelines/createPipeline",
    async (pipelineData) => {
        const response = await axios.post(PIPELINES_API.CREATE, pipelineData);
        const { success, data } = response.data;

        if (success) {
            return data;
        } else {
            throw new Error("Failed to create pipeline");
        }
    }
);

// Async thunk to update an existing pipeline
export const updatePipeline = createAsyncThunk(
    "pipelines/updatePipeline",
    async ({ id, pipelineData }) => {
        const response = await axios.put(`${PIPELINES_API.ALL}/${id}`, pipelineData);
        const { success, data } = response.data;

        if (success) {
            return data;
        } else {
            throw new Error("Failed to update pipeline");
        }
    }
);

const pipelinesSlice = createSlice({
    name: "pipelines",
    initialState,
    reducers: {
        updateFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        // Handle pipelines fetching
        builder
            .addCase(fetchPipelines.pending, (state) => {
                state.pipelinesData.status = asyncStatuses.LOADING;
            })
            .addCase(fetchPipelines.fulfilled, (state, action) => {
                state.pipelinesData.status = asyncStatuses.SUCCEEDED;
                state.pipelinesData.data = action.payload;
            })
            .addCase(fetchPipelines.rejected, (state) => {
                state.pipelinesData.status = asyncStatuses.FAILED;
            });

        // Handle pipelines creating
        builder
            .addCase(createPipeline.pending, (state) => {
                state.createPipelineStatus = asyncStatuses.LOADING;
            })
            .addCase(createPipeline.fulfilled, (state) => {
                state.createPipelineStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(createPipeline.rejected, (state) => {
                state.createPipelineStatus = asyncStatuses.FAILED;
            });

        // Handle pipelines updating
        builder
            .addCase(updatePipeline.pending, (state) => {
                state.updatePipelineStatus = asyncStatuses.LOADING;
            })
            .addCase(updatePipeline.fulfilled, (state) => {
                state.updatePipelineStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(updatePipeline.rejected, (state) => {
                state.updatePipelineStatus = asyncStatuses.FAILED;
            });
    },
});

export const { updateFilters } = pipelinesSlice.actions;

export default pipelinesSlice.reducer;
