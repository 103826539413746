export const departmentOptions = [
  { value: 1, label: "Human Resources (HR)" },
  { value: 2, label: "Finance" },
  { value: 3, label: "Marketing" },
  { value: 5, label: "Sales" },
  { value: 6, label: "Operations" },
  { value: 7, label: "Information Administration (IT)" },
  { value: 8, label: "Customer Service" },
  { value: 9, label: "Research and Development (R&D)" },
  { value: 10, label: "Legal" },
  { value: 11, label: "Purchasing/Operations" },
  { value: 12, label: "Administration" },
  { value: 14, label: "Corporate Strategy" },
  { value: 15, label: "Product Management" },
  { value: 16, label: "Quality Assurance (QA)" },
  { value: 17, label: "Training and Development" },
  { value: 18, label: "Corporate Communications" },
];
export const designationOptions = [
  { value: 1, label: "Chief Executive Officer (CEO)" },
  { value: 2, label: "Chief Financial Officer (CFO)" },
  { value: 3, label: "Chief Operating Officer (COO)" },
  { value: 4, label: "Chief Information Officer (CIO)" },
  { value: 5, label: "Chief Marketing Officer (CMO)" },
  { value: 6, label: "Chief Strategy Officer (CSO)" },
  { value: 7, label: "General Counsel" },
  { value: 8, label: "HR Manager" },
  { value: 9, label: "HR Generalist" },
  { value: 10, label: "Recruiter" },
  { value: 11, label: "Training and Development Manager" },
  { value: 12, label: "Compensation and Benefits Specialist" },
  { value: 13, label: "Finance Manager" },
  { value: 14, label: "Accountant" },
  { value: 15, label: "Financial Analyst" },
  { value: 16, label: "Payroll Specialist" },
  { value: 17, label: "Marketing Manager" },
  { value: 18, label: "Brand Manager" },
  { value: 19, label: "Digital Marketing Specialist" },
  { value: 20, label: "Public Relations Manager" },
  { value: 21, label: "Sales Manager" },
  { value: 22, label: "Business Development Manager" },
  { value: 23, label: "Account Executive" },
  { value: 24, label: "Sales Representative" },
  { value: 25, label: "Operations Manager" },
  { value: 26, label: "Supply Chain Manager" },
  { value: 27, label: "Production Supervisor" },
  { value: 28, label: "Logistics Coordinator" },
  { value: 29, label: "IT Manager" },
  { value: 30, label: "Network Administrator" },
  { value: 31, label: "Software Developer" },
  { value: 32, label: "IT Support Specialist" },
  { value: 33, label: "Customer Service Manager" },
  { value: 34, label: "Call Center Supervisor" },
  { value: 35, label: "Technical Support Specialist" },
  { value: 36, label: "Customer Service Representative" },
  { value: 37, label: "D Manager" },
  { value: 38, label: "Product Development Scientist" },
  { value: 39, label: "Research Analyst" },
  { value: 40, label: "Quality Assurance Tester" },
  { value: 41, label: "Corporate Attorney" },
  { value: 42, label: "Compliance Officer" },
  { value: 43, label: "Contract Manager" },
  { value: 44, label: "Paralegal" },
  { value: 45, label: "Operations Manager" },
  { value: 46, label: "Purchasing Agent" },
  { value: 47, label: "Supply Chain Analyst" },
  { value: 48, label: "Inventory Manager" },
  { value: 49, label: "Office Manager" },
  { value: 50, label: "Administrative Assistant" },
  { value: 51, label: "Executive Assistant" },
  { value: 52, label: "Receptionist" },
  { value: 53, label: "Strategic Planning Manager" },
  { value: 54, label: "Business Analyst" },
  { value: 55, label: "Market Research Analyst" },
  { value: 56, label: "Product Manager" },
  { value: 57, label: "Product Owner" },
  { value: 58, label: "Product Development Manager" },
  { value: 59, label: "Product Analyst" },
  { value: 60, label: "QA Manager" },
  { value: 61, label: "Quality Control Inspector" },
  { value: 62, label: "QA Analyst" },
  { value: 63, label: "Test Engineer" },
  { value: 64, label: "Training Manager" },
  { value: 65, label: "Learning and Development Specialist" },
  { value: 66, label: "Corporate Trainer" },
  { value: 67, label: "Communications Manager" },
  { value: 68, label: "Internal Communications Specialist" },
  { value: 69, label: "Media Relations Manager" },
  { value: 70, label: "Public Affairs Specialist" },
];

export const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "divorced", label: "Divorced" },
  { value: "widowed", label: "Widowed" },
  { value: "separated", label: "Separated" },
];
export const bloodGroupOptions = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];
export const nationalityOptions = [{ value: 1, label: "Indian" }];
export const reportingManagerOptions = [
  { value: 2, label: "Amit Banerji " },
  { value: 3, label: "Rakesh Yadav" },
  { value: 4, label: "Amit Kumar" },
  { value: 5, label: "Amit Bajaj" },
  { value: 6, label: "Anil Choudhary" },
];
export const branchOptions = [{ value: 1, label: "TS Bangalore" }];
export const employmentTypeOptions = [
  { value: 1, label: "Full Time Employment" },
  { value: 2, label: "Part Time Employment" },
  { value: 3, label: "Temporary Employment" },
  { value: 4, label: "Freelance Employment" },
  { value: 5, label: "Seasonal Employment" },
  { value: 6, label: "Internship" },
  { value: 7, label: "Apprenticeship" },
  { value: 8, label: "Gig Employment" },
  { value: 9, label: "Volunteer" },
  { value: 10, label: "Casual Employment" },
  { value: 11, label: "On-Call Employment" },
  { value: 12, label: "Employment Off the Record" },
];
export const employeeStatus = [
  { value: "active", label: "Active" },
  { value: "contract ended", label: "Contract Ended" },
  { value: "deceased", label: "Deceased" },
  { value: "deleted", label: "Deleted" },
  { value: "permanent layoff", label: "Permanent Layoff" },
  { value: "probation", label: "Probation" },
  { value: "resigned", label: "Resigned" },
  { value: "retired", label: "Retired" },
  { value: "suspended", label: "Suspended" },
  { value: "temporary layoff", label: "Temporary Layoff" },
  { value: "terminated", label: "Terminated" },
];
export const statuses = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "pending", label: "Pending" },
];

export const contactRelations = [
  { value: "father", label: "Father" },
  { value: "father-in-law", label: "Father-in-law" },
  { value: "mother", label: "Mother" },
  { value: "mother-in-law", label: "Mother-in-law" },
  { value: "spouse", label: "Spouse" },
  { value: "daughter", label: "Daughter" },
  { value: "son", label: "Son" },
  { value: "friend", label: "Friend" },
  { value: "sibling", label: "Sibling" },
  { value: "relative", label: "Relative" },
  { value: "other", label: "Other" },
];


export const familyRelations = [
  { value: "father", label: "Father" },
  { value: "father-in-law", label: "Father-in-law" },
  { value: "mother", label: "Mother" },
  { value: "mother-in-law", label: "Mother-in-law" },
  { value: "spouse", label: "Spouse" },
  { value: "daughter", label: "Daughter" },
  { value: "son", label: "Son" },
  { value: "sibling", label: "Sibling" }
];

export const employeeStages = [   
  { value: "offer-extended", label: "Offer Extended" },
  { value: "offer-accepted", label: "Offer Accepted" },
  { value: "pre-onboarding", label: "Pre-onboarding" },
  { value: "orientation", label: "Orientation" },
  { value: "trainee", label: "Trainee" },
  { value: "probation-period", label: "Probation Period" },
  { value: "confirmed", label: "Confirmed" }
];

export const asyncStatuses = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};