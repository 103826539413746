import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncStatuses } from "../../../../utils/Constants";
import { ACTIVITIES_API } from "./constants";

// Initial state for activities
const initialState = {
    activitiesData: [],
    status: asyncStatuses.IDLE,
    error: null,
};

// Fetch activities for a given entity (e.g. contact, lead, deal)
export const fetchActivities = createAsyncThunk(
    "activities/fetchActivities",
    async ({ entity_id, entity_type }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${ACTIVITIES_API.ALL}?entity_id=${entity_id}&entity_type=${entity_type}`
            );
            if (response.data.success) {
                return response.data.data; // array of activity objects
            } else {
                return rejectWithValue("Failed to fetch activities");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Create a new activity
export const createActivity = createAsyncThunk(
    "activities/createActivity",
    async (activityData, { rejectWithValue }) => {
        try {
            const response = await axios.post(ACTIVITIES_API.CREATE, activityData);
            if (response.data.success) {
                return response.data.data; // created activity
            } else {
                return rejectWithValue("Failed to create activity");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Update an existing activity
export const updateActivity = createAsyncThunk(
    "activities/updateActivity",
    async ({ activityId, activityData }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                ACTIVITIES_API.UPDATE(activityId),
                activityData
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue("Failed to update activity");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Delete an activity
export const deleteActivity = createAsyncThunk(
    "activities/deleteActivity",
    async (activityId, { rejectWithValue }) => {
        try {
            const response = await axios.delete(ACTIVITIES_API.DELETE(activityId));
            if (response.data.success) {
                return activityId;
            } else {
                return rejectWithValue("Failed to delete activity");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const activitiesSlice = createSlice({
    name: "activities",
    initialState,
    reducers: {
        // You can add synchronous reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            // Fetch Activities
            .addCase(fetchActivities.pending, (state) => {
                state.status = asyncStatuses.LOADING;
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.status = asyncStatuses.SUCCEEDED;
                state.activitiesData = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.status = asyncStatuses.FAILED;
                state.error = action.payload;
            })
            // Create Activity
            .addCase(createActivity.fulfilled, (state, action) => {
                state.activitiesData.push(action.payload);
            })
            // Update Activity
            .addCase(updateActivity.fulfilled, (state, action) => {
                const index = state.activities.findIndex(
                    (act) => act.id === action.payload.id
                );
                if (index !== -1) {
                    state.activitiesData[index] = action.payload;
                }
            })
            // Delete Activity
            .addCase(deleteActivity.fulfilled, (state, action) => {
                state.activitiesData = state.activities.filter(
                    (act) => act.id !== action.payload
                );
            });
    },
});

export default activitiesSlice.reducer;
