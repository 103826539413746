import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncStatuses } from "../../../../utils/Constants";
import {LEADS_API} from "./constants";

const initialState = {
    leadsData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    leadDetailsByID: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    kanbanData : null,
    filters: {
        search: "",
        conditions:"",
        orderField: "name",
        order: "asc",
        limit: 10,
        offset: 0,
    },
    createLeadStatus: asyncStatuses.IDLE,
    updateLeadStatus: asyncStatuses.IDLE,
    selectedPipelineId: null,
};

// ** Transform Leads into Kanban Format with Proper Sorting **
const transformToKanbanFormat = (leads, pipelines, selectedPipelineId) => {
    if (!pipelines || !selectedPipelineId || !leads) return null;

    const kanbanData = {};

    // ** Get selected pipeline and sort its stages by stage_order **
    const selectedPipeline = pipelines.find(p => p.id === selectedPipelineId);
    if (!selectedPipeline) return null;

    const sortedStages = [...selectedPipeline.stages].sort((a, b) => a.stage_order - b.stage_order);

    // ** Initialize all stages **
    sortedStages.forEach(stage => {
        kanbanData[stage.stage_id] = {
            stage_name: stage.stage_name,
            stage_order: stage.stage_order,
            is_final: stage.is_final_stage || false,
            color: stage.color || "gray",
            leads: [],
        };
    });


    // ** Assign leads to their respective stages **
    leads.forEach(lead => {
        const stageId = lead.pipeline_info?.current_stage_id;

        kanbanData[stageId].leads.push({
            id: lead.lead_id.toString(),
            company: lead.company_name || "Unknown Company",
            abbreviation: lead.name ? lead.name : "?",
            amount: lead.value || "N/A",
            email: lead.contacts?.length ? lead.contacts[0].email : "No Email",
            phone: lead.contacts?.length ? lead.contacts[0].phone_number_1 : "No Phone",
            priority: lead.priority || "low",
            status: lead.status || "active",
            owner_name: lead.owner_name || "Unknown",
            created_at: lead.created_at ? new Date(lead.created_at).toLocaleDateString() : "N/A",
            updated_at: lead.updated_at ? new Date(lead.updated_at).toLocaleDateString() : "N/A",
            tags: Array.isArray(lead.tags) && lead.tags.length > 0 ? lead.tags : ["No Tags"],
            pipeline_id: selectedPipelineId,
            stage_id: stageId,
        });
    });

    return kanbanData;
};

// Async thunk to fetch leads
export const fetchLeads = createAsyncThunk("leads/fetchLeads", async (_, { getState }) => {
    const state = getState();
    const { search, orderField, order, limit, offset, conditions} = state.leads.filters;
    const pipelines = state.masters.filteredPipelines;
    const selectedPipelineId = state.leads.selectedPipelineId;

    const params = new URLSearchParams({
        filter: search,
        conditions,
        orderField,
        order,
        limit,
        offset,
    }).toString();

    const response = await axios.get(`${LEADS_API.ALL}?${params}`);
    const { success, data, total } = response.data;

    if (success) {
        const transformedData = data.map((lead) => ({
            ...lead,
            tags: JSON.parse(lead.tags) || [],
            pipeline_info: JSON.parse(lead.pipeline_info) || {},
            all_stages: JSON.parse(lead.all_stages) || [],
            pipeline_logs: JSON.parse(lead.pipeline_logs) || [],
            contacts: JSON.parse(lead.contacts) || [],
        }));
        let  kanbanFormattedData = {};
        if(selectedPipelineId && pipelines){
            kanbanFormattedData = transformToKanbanFormat(transformedData, pipelines, selectedPipelineId);
        }
        return { total, leads: transformedData, kanbanData: kanbanFormattedData};
    } else {
        throw new Error("Failed to fetch leads");
    }
});

// Async thunk to create a new lead
export const createLead = createAsyncThunk("leads/createLead", async (leadData) => {
    const response = await axios.post(LEADS_API.CREATE, leadData);
    const { success, data } = response.data;

    if (success) {
        return data;
    } else {
        throw new Error("Failed to create lead");
    }
});

// Async thunk to fetch a single lead by ID
export const fetchLeadById = createAsyncThunk("leads/fetchLeadById", async (id) => {
    try {
        const response = await axios.get(`${LEADS_API.ALL}/${id}`);
        const { success, data } = response.data;
        if (success) {
            return {
                ...data,
                tags: JSON.parse(data.tags) || [],
                pipeline_info: JSON.parse(data.pipeline_info) || {},
                all_stages: JSON.parse(data.all_stages) || [],
                pipeline_logs: JSON.parse(data.pipeline_logs) || [],
                contacts: JSON.parse(data.contacts) || [],
                modules_submodules:JSON.parse(data.modules_submodules) || [],
            };
        } else {
            throw new Error("Failed to fetch lead details");
        }
    } catch (error) {
        throw error;
    }
});

// Async thunk to update an existing lead
export const updateLead = createAsyncThunk(
    "leads/updateLead",
    async ({ id, leadData }) => {
        const response = await axios.put(`${LEADS_API.ALL}/${id}`, leadData);
        const { success, data } = response.data;

        if (success) {
            return data;
        } else {
            throw new Error("Failed to update lead");
        }
    }
);

export const updateLeadStage = createAsyncThunk(
    "leads/updateLeadStage",
    async ({ leadId, newStageId, pipelineId, logMessage, userId , filter }, { dispatch }) => {
        try {
            const response = await axios.post(LEADS_API.UPDATE_STAGE, {
                leadId: leadId,
                newStageId: newStageId,
                logMessage: logMessage,
                pipelineId: pipelineId
            });
            if (response.data.success) {
                dispatch(fetchLeads());
                return { leadId, newStageId, pipelineId };
            } else {
                throw new Error("Failed to update lead stage");
            }
        } catch (error) {
            throw error;
        }
    }
);

const leadsSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
        updateFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        setSelectedPipelineId(state, action) {
            state.selectedPipelineId = action.payload;
            state.leadsData.kanbanData = null;
        },
        resetLeadDetailsByID(state) {
            state.leadDetailsByID = {
                data: null,
                status: asyncStatuses.IDLE,
            };
        },
        resetFilters(state){
            state.filters = {
                search: "",
                conditions: "",
                orderField: "name",
                order: "asc",
                limit: 10,
                offset: 0,
            }
        },
        resetData(state) {
            state.leadsData = {
                data: null,
                status: asyncStatuses.IDLE,
            }
        }
    },
    extraReducers: (builder) => {
        // Handle leads fetching
        builder
            .addCase(fetchLeads.pending, (state) => {
                state.leadsData.status = asyncStatuses.LOADING;
            })
            .addCase(fetchLeads.fulfilled, (state, action) => {
                state.leadsData.status = asyncStatuses.SUCCEEDED;
                state.leadsData.data = action.payload;
                state.kanbanData = action.payload.kanbanData;
            })
            .addCase(fetchLeads.rejected, (state) => {
                state.leadsData.status = asyncStatuses.FAILED;
            });

        // Handle lead creation
        builder
            .addCase(createLead.pending, (state) => {
                state.createLeadStatus = asyncStatuses.LOADING;
            })
            .addCase(createLead.fulfilled, (state) => {
                state.createLeadStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(createLead.rejected, (state) => {
                state.createLeadStatus = asyncStatuses.FAILED;
            });

        // Handle lead updating
        builder
            .addCase(updateLead.pending, (state) => {
                state.updateLeadStatus = asyncStatuses.LOADING;
            })
            .addCase(updateLead.fulfilled, (state) => {
                state.updateLeadStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(updateLead.rejected, (state) => {
                state.updateLeadStatus = asyncStatuses.FAILED;
            });

        // Handle lead details by ID
        builder
            .addCase(fetchLeadById.pending, (state) => {
                state.leadDetailsByID.status = asyncStatuses.LOADING;
            })
            .addCase(fetchLeadById.fulfilled, (state, action) => {
                state.leadDetailsByID.status = asyncStatuses.SUCCEEDED;
                state.leadDetailsByID.data = action.payload;
            })
            .addCase(fetchLeadById.rejected, (state) => {
                state.leadDetailsByID.status = asyncStatuses.FAILED;
            });
    },
});

export const { updateFilters,resetData, resetFilters ,setSelectedPipelineId, resetLeadDetailsByID } = leadsSlice.actions;

export default leadsSlice.reducer;
