import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { asyncStatuses } from "../../../../utils/Constants";
import { CONTACTS_API } from "./constants";

const initialState = {
    contactsData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    contactDetailsByID: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    filters: {
        search: "",
        orderField: "first_name",
        order: "asc",
        limit: 10,
        offset: 0,
    },
    createContactStatus: asyncStatuses.IDLE,
    updateContactStatus: asyncStatuses.IDLE,
};

// Async thunk to fetch contacts
export const fetchContacts = createAsyncThunk(
    "contacts/fetchContacts",
    async (_, { getState }) => {
        const state = getState().contacts;
        const { search, orderField, order, limit, offset } = state.filters;

        const params = new URLSearchParams({
            filter: search,
            orderField,
            order,
            limit,
            offset,
        }).toString();

        const response = await axios.get(`${CONTACTS_API.ALL}?${params}`);

        const { success, data, total } = response.data;
        const transformedData = data.map((contact) => ({
            ...contact,
            tags: JSON.parse(contact.tags) || [],
            companies: JSON.parse(contact.companies) || [],
        }));
        if (success) {
            return { total, contacts: transformedData };
        } else {
            throw new Error("Failed to fetch contacts");
        }
    }
);

// Async thunk to create a new contact
export const createContact = createAsyncThunk(
    "contacts/createContact",
    async (contactData) => {
        const response = await axios.post(CONTACTS_API.CREATE, contactData);
        const { success, data } = response.data;

        if (success) {
            return data;
        } else {
            throw new Error("Failed to create contact");
        }
    }
);

// Async thunk to fetch a single contact by ID
export const fetchContactById = createAsyncThunk(
    "contacts/fetchContactById",
    async (id) => {
        try {
            const response = await axios.get(`${CONTACTS_API.ALL}/${id}`);
            const { success, data } = response.data;
            if (success) {
                return {
                    ...data,
                    tags: JSON.parse(data.tags) || [],
                    companies: JSON.parse(data.companies) || [],
                };
            } else {
                throw new Error("Failed to fetch contact details");
            }
        } catch (error) {
            throw error;
        }
    }
);

// Async thunk to update an existing contact
export const updateContact = createAsyncThunk(
    "contacts/updateContact",
    async ({ id, contactData }) => {
        const response = await axios.put(`${CONTACTS_API.ALL}/${id}`, contactData);
        const { success, data } = response.data;

        if (success) {
            return data;
        } else {
            throw new Error("Failed to update contact");
        }
    }
);

const contactsSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        updateFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state){
            state.filters = {
                search: "",
                    conditions: "",
                    orderField: "name",
                    order: "asc",
                    limit: 10,
                    offset: 0,
            }
        },
        resetData(state){
            state.contactsData = {
                data: null,
                    status: asyncStatuses.IDLE,
            }
        }
    },
    extraReducers: (builder) => {
        // Handle contacts fetching
        builder
            .addCase(fetchContacts.pending, (state) => {
                state.contactsData.status = asyncStatuses.LOADING;
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                state.contactsData.status = asyncStatuses.SUCCEEDED;
                state.contactsData.data = action.payload;
            })
            .addCase(fetchContacts.rejected, (state) => {
                state.contactsData.status = asyncStatuses.FAILED;
            });

        // Handle contact creating
        builder
            .addCase(createContact.pending, (state) => {
                state.createContactStatus = asyncStatuses.LOADING;
            })
            .addCase(createContact.fulfilled, (state) => {
                state.createContactStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(createContact.rejected, (state) => {
                state.createContactStatus = asyncStatuses.FAILED;
            });

        // Handle contact updating
        builder
            .addCase(updateContact.pending, (state) => {
                state.updateContactStatus = asyncStatuses.LOADING;
            })
            .addCase(updateContact.fulfilled, (state) => {
                state.updateContactStatus = asyncStatuses.SUCCEEDED;
            })
            .addCase(updateContact.rejected, (state) => {
                state.updateContactStatus = asyncStatuses.FAILED;
            });

        // Handle contact details by Ids
        builder
            .addCase(fetchContactById.pending, (state) => {
                state.contactDetailsByID.status = asyncStatuses.LOADING;
            })
            .addCase(fetchContactById.fulfilled, (state, action) => {
                state.contactDetailsByID.status = asyncStatuses.SUCCEEDED;
                state.contactDetailsByID.data = action.payload;
            })
            .addCase(fetchContactById.rejected, (state) => {
                state.updateContactStatus.status = asyncStatuses.FAILED;
            });
    },
});

export const { updateFilters, resetData,resetFilters } = contactsSlice.actions;

export default contactsSlice.reducer;
