// PIPELINES Base API URL
import {getApiUrl} from "../../../../utils/AuthUtils";

const CONTACTS_BASE_URL = `${getApiUrl()}/v1/api/crm/contacts`;

// Contacts API Endpoints
export const CONTACTS_API = {
    ALL: `${CONTACTS_BASE_URL}`, // GET - Fetch all contacts (with filtering, sorting, pagination)
    CREATE: `${CONTACTS_BASE_URL}/create`, // POST - Create a new contacts
}
