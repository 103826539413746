import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";

import "bootstrap/dist/css/bootstrap.min.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import axios from "axios";

const PdfViewer = ({ pdfUrl, isOpen, pdfTitle, closeModal }) => {
  const [newPdfUrl, setNewPdfUrl] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        // Fetch the presigned URL from the backend (which returns a URL to the S3 file)
        const response1 = await axios.get(pdfUrl); // pdfUrl is the API that returns a presigned URL
        const newPDFURL = response1.data.data.url; // Extract the presigned URL from the response
        setNewPdfUrl(newPDFURL);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    if (isOpen && pdfUrl) {
      fetchPdf();
    }

  }, [pdfUrl, isOpen]);

  return (
    <>
      {isOpen && newPdfUrl && (
        <div className="modal fade show d-block">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header header-border justify-content-between bg-primary">
                <h5 className="modal-title text-white">{pdfTitle}</h5>
                <button
                  type="button"
                  className="btn-close position-static"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setNewPdfUrl(null);
                    closeModal();
                  }}
                  style={{ marginRight: "10px" }}
                >
                  <span
                    aria-hidden="true"
                    style={{
                      fontSize: "25px",
                      marginTop: "-21px",
                      position: "absolute",
                    }}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body text-center">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={newPdfUrl} />
                </Worker>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PdfViewer;
