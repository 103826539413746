import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user";
import pipelinesReducer from "./views/pages/Masters/Pipelines/pipelinesSlice";
import mastersReducer from "./views/pages/Masters/MasterSlice";
import contactsReducer from "./views/pages/Crm/Contacts/contactsSlice";
import leadsReducer from "./views/pages/Crm/Leads/leadsSlice";
import activitiesReducer from "./views/pages/Crm/Activities/activitiesSlice";
import dealsReducer from "./views/pages/Crm/Deals/dealsSlice";


const reducer = {
  user: userSlice.reducer, // Access the 'reducer' property of the userSlice
  pipelines: pipelinesReducer,
  masters: mastersReducer,
  contacts: contactsReducer,
  leads: leadsReducer,
  activities: activitiesReducer,
  deals: dealsReducer
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
