import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {MASTERS_API} from "./constants";
import { asyncStatuses } from "../../../utils/Constants";


const initialState = {
    entityMastersData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    leadMastersData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    rolesMastersData: {
        data: null,
        status: asyncStatuses.IDLE,
    },
    pipelines: [],
    filteredPipelines: [],
};

// Async thunk to fetch entity masters
export const fetchEntityMasters = createAsyncThunk(
    "master/fetchEntityMasters",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(MASTERS_API.ENTITY_MASTER);
            const { status, data, message } = response.data;

            if (status === "success") {
                return data;
            } else {
                return rejectWithValue(message || "Failed to fetch entity masters");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "An error occurred");
        }
    }
);

// Async thunk to fetch entity masters
export const fetchRoleMasters = createAsyncThunk(
    "master/fetchRoleMasters",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(MASTERS_API.USER_GROUP_MASTERS);
            const { success, data } = response.data;

            if (success) {
                return data;
            } else {
                return rejectWithValue( "Failed to fetch entity masters");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "An error occurred");
        }
    }
);


// Async thunk to fetch entity masters
export const fetchLeadMasters = createAsyncThunk(
    "master/fetchLeadMasters",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(MASTERS_API.LEAD_MASTER);
            const { success, data, message } = response.data;

            if (success) {
                return {
                    ...data,
                    pipelines: data.pipelines.map((pipeline) => ({
                        ...pipeline,
                        stages: JSON.parse(pipeline.stages) || [],
                        module_mappings: JSON.parse(pipeline.module_mappings) || [],
                        access_users: JSON.parse(pipeline.access_users) || [],
                    })),

                };
            } else {
                return rejectWithValue(message || "Failed to fetch leads masters");
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "An error occurred");
        }
    }
);

/**
 * Filters pipelines based on the given submodule slug.
 *
 * @param {Array} pipelines - The full pipeline list.
 * @param {String} submoduleSlug - The slug of the submodule.
 * @returns {Array} - Filtered pipelines that match the given submodule slug.
 */
const filterPipelinesBySubmoduleSlug = (pipelines, submoduleSlug) => {
    const modules = JSON.parse(localStorage.getItem("userModulePermissions") || "[]");

    // Get all allowed submodule IDs that match the given submodule slug
    const allowedSubmoduleIds = new Set(
        modules.flatMap(module =>
            module.submodules
                .filter(submodule => submodule.slug === submoduleSlug)
                .map(submodule => submodule.id)
        )
    );

    // Filter pipelines based on allowed submodule IDs
    return pipelines.filter(pipeline =>
        pipeline.module_mappings.some(mapping => allowedSubmoduleIds.has(mapping.submodule_id))
    );
};

const mastersSlice = createSlice({
    name: "masters",
    initialState,
    reducers: {
        resetEntityMasters(state) {
            state.entityMastersData.data = null;
            state.entityMastersData.status = asyncStatuses.IDLE;
            state.fetchEntityMastersStatus = asyncStatuses.IDLE;
        },
        resetLeadMasters(state) {
            state.leadMastersData.data = null;
            state.leadMastersData.status = asyncStatuses.IDLE;
            state.fetchleadMastersStatus = asyncStatuses.IDLE;
        },
        filterPipelines: (state, action) => {
            state.filteredPipelines = filterPipelinesBySubmoduleSlug(state.pipelines, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEntityMasters.pending, (state) => {
                state.entityMastersData.status = asyncStatuses.LOADING;
                state.fetchEntityMastersStatus =asyncStatuses.LOADING;
            })
            .addCase(fetchEntityMasters.fulfilled, (state, action) => {
                state.entityMastersData.status = asyncStatuses.SUCCEEDED;
                state.entityMastersData.data = action.payload;
            })
            .addCase(fetchEntityMasters.rejected, (state, action) => {
                state.entityMastersData.status = asyncStatuses.FAILED;
                state.entityMastersData.error = action.payload;
            });

        // Roles Master data fetch
        builder
            .addCase(fetchRoleMasters.pending, (state) => {
                state.rolesMastersData.status = asyncStatuses.LOADING;
            })
            .addCase(fetchRoleMasters.fulfilled, (state, action) => {
                state.rolesMastersData.status = asyncStatuses.SUCCEEDED;
                state.rolesMastersData.data = action.payload;
            })
            .addCase(fetchRoleMasters.rejected, (state, action) => {
                state.rolesMastersData.status = asyncStatuses.FAILED;
                state.rolesMastersData.error = action.payload;
            });

        // Lead Master data fetch
        builder
            .addCase(fetchLeadMasters.pending, (state) => {
                state.leadMastersData.status = asyncStatuses.LOADING;
                state.fetchleadMastersStatus = asyncStatuses.LOADING;
            })
            .addCase(fetchLeadMasters.fulfilled, (state, action) => {
                state.leadMastersData.status = asyncStatuses.SUCCEEDED;
                state.leadMastersData.data = action.payload;
                state.pipelines = action.payload.pipelines;
                state.filteredPipelines = action.payload.pipelines;
            })
            .addCase(fetchLeadMasters.rejected, (state, action) => {
                state.leadMastersData.status = asyncStatuses.FAILED;
                state.leadMastersData.error = action.payload;
            });

    },
});

// Exporting actions
export const { resetEntityMasters,filterPipelines } = mastersSlice.actions;

// Exporting reducer
export default mastersSlice.reducer;
