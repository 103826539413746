// PIPELINES Base API URL
import {getApiUrl} from "../../../../utils/AuthUtils";

const PIPELINES_BASE_URL = `${getApiUrl()}/v1/api/masters/pipelines`;

// Pipeline API Endpoints
export const PIPELINES_API = {
    ALL: `${PIPELINES_BASE_URL}`, // GET - Fetch all pipelines (with filtering, sorting, pagination)
    CREATE: `${PIPELINES_BASE_URL}/create`, // POST - Create a new pipeline
}
