import { getApiUrl } from "../../../../utils/AuthUtils";

const ACTIVITIES_BASE_URL = `${getApiUrl()}/v1/api/crm/activities`;

export const ACTIVITIES_API = {
    // GET - Fetch all activities (you can pass query params such as entity_id and entity_type)
    ALL: `${ACTIVITIES_BASE_URL}`,
    // POST - Create a new activity
    CREATE: `${ACTIVITIES_BASE_URL}/create`,
    // PUT - Update an existing activity (activity id will be appended)
    UPDATE: (activityId) => `${ACTIVITIES_BASE_URL}/${activityId}`,
    // DELETE - Delete an activity (activity id will be appended)
    DELETE: (activityId) => `${ACTIVITIES_BASE_URL}/${activityId}`,
};
